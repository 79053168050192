import PropTypes from 'prop-types';
import React from 'react';
import { classNames, If } from 'react-extras';
import Viewer from 'react-viewer';
import { Tooltip } from 'react-tippy';

export default class ImageViewer extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      source: props.recipe || 'boss-images',
      recipe: props.recipe || 'cover',
      width: props.width || 522,
      height: props.height || 340,
      blur: false,
      blurValue: 3,
      grayscale: false,
      recipesBar: props.recipesBar || 'on',
      optionsBar: props.optionsBar || 'on',
      path: props.path || "/examples/02.jpg",
      recipes: {
        width: `${props.service}/${props.source}/width/:width/:options:path`,
        height: `${props.service}/${props.source}/height/:height/:options:path`,
        cover: `${props.service}/${props.source}/cover/:widthx:height/:options:path`,
      }
    };

    const defaultParams = this.getDefaultDimensions();
    this.state.width = defaultParams.width;
    this.state.height = defaultParams.height;
  }

  componentDidMount() {
    this.setPreviewImage();
  }

  setPreviewImage = () => {
    const previewImageUrl = this.getImageUrl();
    document.getElementById('preview-image').style.backgroundImage = `url("${previewImageUrl}")`;
    this.setState({ previewImg: previewImageUrl });
  }

  getDefaultDimensions = (recipe = this.state.recipe) => {
    if (recipe === "cover") {
      return { width: 500, height: 500 };
    } else if (recipe === 'width') {
      return { width: 700, height: this.state.height };
    }

    return { width: this.state.width, height: 700 };
  }

  setRecipe = (recipe, e) => {
    e.preventDefault();
    const params = this.getDefaultDimensions(recipe);
    this.setState({ recipe, width: params.width, height: params.height }, this.setPreviewImage);
  }

  setOption = (option, e) => {
    e.preventDefault();
    this.setState({ [option]: !this.state[option] }, this.setPreviewImage);
  }

  getOptions = () => {
    const optionsArray = ['format:auto'];
    let options;

    ['grayscale', 'blur'].forEach((option) => {
      if (this.state[option]) {
        if (option === 'grayscale') {
          optionsArray.push(`${option}:true`)
        } else if (option === 'blur') {
          optionsArray.push(`${option}:${this.state.blurValue}`);
        }
      }
    });

    if (optionsArray.length) {
      options = `${optionsArray.join(',')}/`;
    }

    return options || '';
  }

  getImageUrl = () => {
    const optionsArray = [];
    let options;

    ['grayscale', 'blur'].forEach((option) => {
      if (this.state[option]) {
        if (option === 'grayscale') {
          optionsArray.push(`${option}:true`)
        } else if (option === 'blur') {
          optionsArray.push(`${option}:${this.state.blurValue}`);
        }
      }
    });

    if (optionsArray.length) {
      options = `${optionsArray.join(',')}/`;
    }

    const width = Math.max(1, Number(this.state.width));
    const height = Math.max(1, Number(this.state.height));

    return this.state.recipes[this.state.recipe]
              .replace(':width', width)
              .replace(':height', height)
              .replace(':options', this.getOptions())
              .replace('/:path', this.state.path);
  }

  toogleImageViewer = (e) => {
    e.preventDefault();
    this.setState({ visible: !this.state.visible });
  }

  changeParams = (param, e) => {
    let value;
    if (e.target.value) {
      value = Math.min(4000, e.target.value);
    } else {
      value = e.target.value;
    }

    this.setState({ [param]: value });
  }

  render() {
    const coverClass = classNames({ active: this.state.recipe === 'cover' });
    const widthClass = classNames({ active: this.state.recipe === 'width' });
    const heightClass = classNames({ active: this.state.recipe === 'height' });

    const blurClass = classNames('icon icon-blur', { 'icon-blur-on': this.state.blur });
    const grayscaleClass = classNames('icon icon-gs', { 'icon-gs-on': this.state.grayscale });

    const recipeText = this.state.recipe == 'cover'
        ? `/mywebsite-images/${this.state.recipe}/${this.state.width}x${this.state.height}/${this.getOptions()}`
        : `/mywebsite-images/${this.state.recipe}/${this.state.width}/${this.getOptions()}`

    return (
      <div className="in-action-frame">
        <div className="in-action-image" id="preview-image" onClick={this.toogleImageViewer}>
          <Viewer
            visible={this.state.visible}
            onClose={() => { this.setState({ visible: false }); }}
            images={[{ src: this.state.previewImg , alt: 'Real Size Image' }]}
          />
          <a href="#" className="btn btn-small btn-green-sh">view in real size</a>
        </div>
        <div className="in-action-tools">
          <If condition={this.state.recipesBar === 'on'}>
            <div className="recipes">
              <ul>
                <li>
                  <Tooltip title="The final image will have an exact proportion with the Smart Cropping Technology." position="top" trigger="mouseenter">
                    <a href="#" className={coverClass} onClick={this.setRecipe.bind(null, 'cover')}>Cover</a>
                  </Tooltip>
                  </li>
                <li>
                  <Tooltip title="The final image will have its width proportionally calculated and a fixed height." position="top" trigger="mouseenter">
                    <a href="#" className={widthClass} onClick={this.setRecipe.bind(null, 'width')}>Width</a>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip title="The final image will have its height proportionally calculated and a fixed width." position="top" trigger="mouseenter">
                    <a href="#" className={heightClass} onClick={this.setRecipe.bind(null, 'height')}>Height</a>
                  </Tooltip>
                </li>
              </ul>
            </div>
          </If>
          <If condition={this.state.optionsBar === 'on'}>
            <div className="options">
              <ul>
                <li>
                  <Tooltip title="Apply a blur filter." position="top" trigger="mouseenter">
                    <a href="#" onClick={this.setOption.bind(null, 'blur')}><i className={blurClass}></i></a>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip title="Apply a black & white filter." position="top" trigger="mouseenter">
                    <a href="#" onClick={this.setOption.bind(null, 'grayscale')}><i className={grayscaleClass}></i></a>
                  </Tooltip>
                </li>
              </ul>
            </div>
          </If>
        </div>
        <div className="in-action-recipe-options">
          <If condition={this.state.recipe === "cover"}>
            <input type="number" value={this.state.width} onChange={this.changeParams.bind(null, 'width')} placeholder="Width" />
            &nbsp; x &nbsp;
            <input type="number" value={this.state.height} onChange={this.changeParams.bind(null, 'height')} placeholder="Height" />
          </If>

          <If condition={this.state.recipe === "width"}>
            <input type="number" value={this.state.width} onChange={this.changeParams.bind(null, 'width')} placeholder="Width" /> &nbsp; px
          </If>

          <If condition={this.state.recipe === "height"}>
            <input type="number" value={this.state.height} onChange={this.changeParams.bind(null, 'height')} placeholder="Height" /> &nbsp; px
          </If>
          &nbsp; &nbsp; <button onClick={this.setPreviewImage} className="btn btn-green btn-small">Generate</button>
        </div>
        <div className="in-action-url-recipe">
          <div className="recipe">{recipeText}</div>
          <div className="client-url">images/01.jpg</div>
        </div>
      </div>
    );
  }
}
