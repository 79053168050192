import PropTypes from 'prop-types';
import React from 'react';
import { If, classNames } from 'react-extras';

export default class Plans extends React.Component {
  static propTypes = {
    plans: PropTypes.object.isRequired,
    subscriptionPlan: PropTypes.string,
    hideFeatures: PropTypes.bool,
    hideButtons: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      hideFeatures: props.hideFeatures,
      hideButtons: props.hideButtons,
      period: 'monthly',
      plans: props.plans,
      plan: props.plans.monthly,
      selected: props.plans.monthly[1]
    };
  }

  componentDidMount() {
  }

  render() {
    const monthlyClasses = classNames({ active: this.state.period === 'monthly' })
    const planClasses = classNames('plans-section-plan plans-section-selected')
    const subscriptionPlan = this.props.subscriptionPlan;

    return (
      <div>
        <div className="plans-section-header">
          <div className="title">
            BILLING/PLAN
          </div>
          <div>
            <div className="periods">
              <a href="#" className={monthlyClasses} onClick={(e) => e.preventDefault()}>Monthly</a>
            </div>
          </div>
        </div>

        <div className="plans-section-plans">
          <div className={planClasses}>
            <h4>👌 Pay As You Grow</h4>
            <div className="price">
              <sub>$</sub>
              <span>{subscriptionPlan && subscriptionPlan === "as-you-go-monthly-usd" ? '1.00' : '1.20'}</span> each 1000 <br/>
              <p>active master images</p>
            </div> <br />
            <ul>
              <If condition={!this.state.hideFeatures}>
                <li>World-wide <a href="/docs/content-delivery-network">CDN</a>.</li>
                <li>Unlimited Transformations.</li>
                <li>Unlimited Websites.</li>
                <li>Elastic Bandwidth.</li>
                <li>All features included.</li>
                <li>E-mail/Chat Support.</li>
              </If>
            </ul>

              <If condition={!this.state.hideButtons}>
                <div>
                  <a href={`/users/sign_up?plan=as-you-go&period=monthly`} className="btn btn-white">
                    Start Free Trial
                    <br />
                    <span>No Credit Card Required</span>
                  </a>
                </div>
              </If>
          </div>
        </div>
      </div>
    );
  }
}
