import React from 'react';
import Typed from 'typed.js';


export default class DemoViewer extends React.Component {
    static propTypes = {};

    componentDidMount() {
        const bg = document.querySelector('.demo-image');
        let step = -1;

        const initialState = () => {
            bg.style.width = '90%';
            bg.style.backgroundSize = '100%';
            bg.style.backgroundPosition = '50%';
            bg.style.filter = 'none';
        };

        const steps = [
            initialState,
            () => { // width
                bg.style.width = '50%';
            }, // height
            () => {
                bg.style.width = '90%';
                bg.style.height = '90%';
            },
            () => { // cover:center
                bg.style.width = '67%';
                bg.style.height = '70%';
                bg.style.backgroundSize = '193%';
            },
            () => { // cover:center, fp-x, fp-y
                bg.style.backgroundPosition = '85% 50%';
            },
            () => { // cover:center, fp-x, fp-y, fp-z:3
                bg.style.backgroundPosition = '79% 64%';
                bg.style.backgroundSize = '450%';
            },
            () => {  // cover:center, fp-x, fp-y, fp-z, grayscale
                bg.style.filter = 'grayscale(1)';
            },
            () => {  // cover:center, fp-x, fp-y, fp-z, grayscale, gamma
                bg.style.filter = 'grayscale(1) contrast(150%)';
            }
        ]

        const options = {
            strings: [
                '',
                'https://img.imageboss.me/<span class="source">myimages</span>/<span class="operation">width</span>/<span class="dimensions">300</span>/',
                'https://img.imageboss.me/<span class="source">myimages</span>/<span class="operation">height</span>/<span class="dimensions">300</span>/',
                'https://img.imageboss.me/<span class="source">myimages</span>/<span class="operation">cover</span>/<span class="dimensions">300x300</span>/',
                'https://img.imageboss.me/<span class="source">myimages</span>/<span class="operation">cover</span>/<span class="dimensions">300x300</span>/<span class="options">fp-x:0.66</span>,<span class="options">fp-y:0.46</span>/',
                'https://img.imageboss.me/<span class="source">myimages</span>/<span class="operation">cover</span>/<span class="dimensions">300x300</span>/<span class="options">fp-x:0.66</span>,<span class="options">fp-y:0.46</span>,<span class="options">fp-z:3</span>/',
                'https://img.imageboss.me/<span class="source">myimages</span>/<span class="operation">cover</span>/<span class="dimensions">300x300</span>/<span class="options">fp-x:0.66</span>,<span class="options">fp-y:0.46</span>,<span class="options">fp-z:3</span>,<span class="options">grayscale:true</span>/',
                'https://img.imageboss.me/<span class="source">myimages</span>/<span class="operation">cover</span>/<span class="dimensions">300x300</span>/<span class="options">fp-x:0.66</span>,<span class="options">fp-y:0.46</span>,<span class="options">fp-z:3</span>,<span class="options">grayscale:true</span>,<span class="options">gamma:2</span>/',
            ],
            typeSpeed: 70,
            backSpeed: 40,
            loop: true,
            onStringTyped: () => {
                const currentStep = steps[++step]
                if (currentStep) {
                    currentStep();
                } else {
                    step = 0;
                    steps[0]();
                }
             },
          };

        const typed = new Typed('.demo-typing', options);
    }

    render() {
        return (
            <div>
                <div
                    className="demo-image"
                    data-imageboss-width="2000"
                    data-imageboss-dpr="false"
                    data-imageboss-bg-src={this.props.demoImage}
                ></div>
                <div className="demo-typed"><span className="demo-typing"></span>images/photo.jpg</div>
            </div>

        );
    }
}
